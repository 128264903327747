import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import Loader from '../../../Shared/Loader';
import { format } from 'date-fns';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useRef } from 'react';


const PaymentField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [payments, setPayments] = useState([]);
    const [updatePayment, setUpdatePayment] = useState(null);
    const [suppliers, setSuppliers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isExpend, setIsExpend] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isSupplierSearching, setIsSupplierSearching] = useState(false);
    const [supplierText, setSupplierText] = useState([]);
    const [supplierSearch, setSupplierSearch] = useState([]);
    const [sort, setSort] = useState('Newest First');
    const [isFilter, setIsFilter] = useState(false);
    const [currentSupplier, setCurrentSupplier] = useState([]);
    const [finalDue, setFinalDue] = useState(0);
    const [isCollect, setIsCollect] = useState(false);

    console.log(payments);
    const sortRef = useRef('');
    const codeRef = useRef('');
    const nameRef = useRef('');




    const handleSort = () => {
        const sortValue = sortRef.current.value;
        if (sortValue === "Oldest First") {
            setSort("Oldest First")
        } else {
            setSort("Newest First")
        }
    }
    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    // useEffect(() => {
    //     fetch('https://servers.thakurgaonexpress.com/mntthai/getpayments')
    //         .then(res => res.json())
    //         .then(data => {
    //             if (sort !== "Oldest First") {
    //                 setPayments(data)
    //             } else {
    //                 const sorted = data.sort(function (a, b) {
    //                     return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
    //                 });
    //                 setPayments(sorted);
    //             }
    //             setLoading(false)
    //         })
    // }, [sort])

    const fetchData = () => {
        fetch('https://servers.thakurgaonexpress.com/mntthai/getpayments')
            .then(res => res.json())
            .then(data => {
                if (sort !== "Oldest First") {
                    setPayments(data);
                } else {
                    const sorted = data.sort(function (a, b) {
                        return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                    });
                    setPayments(sorted);
                }
            });
    };

    useEffect(() => {
        fetchData(); // Fetch data when the component mounts
    }, [sort]);

    useEffect(() => {
        fetch('https://servers.thakurgaonexpress.com/mntthai/getsuppliers')
            .then(res => res.json())
            .then(data => {
                const filter = data?.filter(eh => eh?.status === "Active")
                setSuppliers(filter);
                setLoading(false)
            })
    }, [])

    if (loading) {
        return <Loader />
    }

    // const onSubmit = data => {
    //     const currentDate = new Date(data.date);
    //     const formattedDate = format(currentDate, 'MMM-yyyy');
    //     const payment = parseInt(data.amount);
    //     const code = codeRef.current.value;
    //     const name = nameRef.current.value;
    //     const user = userAc?.email.split('@')[0];

    //     const newData = { ...data, code, name, month: formattedDate, payment, user }
    //     fetch('https://servers.thakurgaonexpress.com/mntthai/addpayment', {
    //         method: 'POST',
    //         headers: {
    //             'content-type': 'application/json'
    //         },
    //         body: JSON.stringify(newData),
    //     })
    //         .then(res => res.json())
    //         .then(data => {
    //             toast.success('Payment Added')
    //             reset();
    //             setTimeout(() => { window.location.reload(); }, 3000);
    //         })

    // }

    const onSubmit = data => {
        const currentDate = new Date(data.date);
        const formattedDate = format(currentDate, 'MMM-yyyy');
        const payment = parseInt(data.amount);
        const code = codeRef.current.value;
        const name = nameRef.current.value;
        const user = userAc?.email.split('@')[0];

        const newData = { ...data, code, name, month: formattedDate, payment, user }
        // Function to make the fetch request to add an expense
        const addPayment = () => {
            fetch('https://servers.thakurgaonexpress.com/mntthai/addpayment', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(newData),
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Payment Added');
                    reset();
                    fetchData();
                    setIsExpend(false);
                    setCurrentSupplier(null);
                });
        };

        // Call the function to add the expense
        addPayment();
    };

    const handleDeletePayment = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.thakurgaonexpress.com/mntthai/getpayment/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdatePayment(null);
                    fetchData();
                })
        }
    };

    const handleFilteredData = e => {
        e.preventDefault();
        const startDate = e.target.startDate.value;
        const endDate = e.target.endDate.value;
        const name = e.target.category.value;
        const sortBy = e.target.sort.value;
        const url = `https://servers.thakurgaonexpress.com/mntthai/filteredpayments?startDate=${startDate}&endDate=${endDate}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                if (name === "All") {
                    if (sortBy !== "Oldest First") {
                        setPayments(data);
                    } else {
                        const sorted = data.sort(function (a, b) {
                            return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                        });
                        setPayments(sorted);
                    }
                } else {

                    if (sortBy !== "Oldest First") {
                        const filteredPayments = data.filter(payment => payment.name === name);
                        setPayments(filteredPayments);
                    } else {
                        const filteredPayments = data.filter(payment => payment.name === name);
                        const sorted = filteredPayments.sort(function (a, b) {
                            return (a?.date > b?.date) ? 1 : ((a?.date < b?.date) ? -1 : 0);
                        });
                        setPayments(sorted);
                    }
                }
                setIsFilter(true);
            })
    }

    const handleUpdatePayment = (payment) => {
        setUpdatePayment(payment);
    }

    const handleSearchResult = (event) => {
        const searchText = event.target.value.toLowerCase();
        const match = payments.filter(payment => payment.name.toLowerCase().includes(searchText)
            || payment.purpose.toLowerCase().includes(searchText) || payment.amount.toLowerCase().includes(searchText)
            || payment.date.toLowerCase().includes(searchText) || payment.invoice.toLowerCase().includes(searchText) ||
            payment.code.toLowerCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const handleSearchSupplier = (event) => {
        setSupplierText(event.target.value)
        const match = suppliers.filter(supplier => supplier.name.toLowerCase().includes(supplierText)
            || supplier.name.includes(supplierText) || supplier.name.toUpperCase().includes(supplierText));
        setSupplierSearch(match);
        setIsSupplierSearching(true);
    }

    const handleSelectSupplier = (supplier) => {
        const opening = parseFloat(supplier?.opening)
        const url = `https://servers.thakurgaonexpress.com/mntthai/filteredsupplier?code=${supplier.code}`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                const due = opening + data?.defaultTotalDue - data?.defaultTotalPayment;
                setCurrentSupplier({ ...supplier, due });
            })
        setIsSupplierSearching(false)
        setSupplierText('');
    }

    const handleFinalDue = (event) => {
        const currentDue = currentSupplier?.due;
        const due = currentDue - parseInt(event?.target?.value);
        console.log(due);
        setFinalDue(due);
        setIsCollect(true);
    }

    const handleApprove = (payment) => {
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.thakurgaonexpress.com/mntthai/getpayment/${payment._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Payment Approved');
                fetchData();
            })
    }

    const totalAmount = !isSearching ? payments.reduce((total, currentValue) => total + parseInt(currentValue?.amount), 0)
        : searchResult.reduce((total, currentValue) => total + parseInt(currentValue?.amount), 0);
    return (
        <div>
            <div className={`${isExpend ? 'shadow-2xl' : ''} px-12 pb-5 mt-5 text-xs`}>
                <div className='text-right'><button onClick={() => setIsExpend(!isExpend)} className='btn btn-sm btn-error text-white'>{isExpend === true ? <AiOutlineMinus className='text-lg font-bold' /> : <AiOutlinePlus className='text-lg font-bold' />}Add Payment</button></div>
                <div className={`${isExpend === true ? "" : "hidden"}`}>
                    <div class="form-control w-full max-w-xs mt-5 col-span-2">
                        <div class="form-control w-full">
                            <input onChange={handleSearchSupplier} value={supplierText} type="text" placeholder="Search Supplier" class="rounded-lg bg-red-300 p-3 placeholder-black  text-center  focus:border-blue-500 focus:outline-none w-full max-w-xs" />
                        </div>
                        <div style={isSupplierSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSupplierSearching ? 'h-16' : "h-8"} w-full overflow-auto`}>
                            {
                                isSupplierSearching ? supplierSearch.map(supplier =>
                                    <label for="update-modal" onClick={() => handleSelectSupplier(supplier)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{supplier.code}. {supplier?.name}</label>) :
                                    suppliers.map(supplier =>
                                        <label for="update-modal" onClick={() => handleSelectSupplier(supplier)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 p-3 text-white text-xs shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{supplier.code}. {supplier?.name}</label>)
                            }
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className={`container grid grid-cols-1 lg:grid-cols-3 gap-1 justify-center items-center`}>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Supplier Code</span>
                            </label>
                            <input disabled ref={codeRef} value={currentSupplier?.code} type="text" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Supplier Name</span>
                            </label>
                            <input disabled ref={nameRef} value={currentSupplier?.name} type="text" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Previews Due</span>
                            </label>
                            <input disabled type="number" value={currentSupplier?.due} class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Amount</span>
                            </label>
                            <input {...register("amount")} onChange={handleFinalDue} type="number" placeholder="Type Amount Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Final Due</span>
                            </label>
                            <input disabled type="number" value={isCollect === true ? finalDue : currentSupplier?.due} class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Select Date</span>
                            </label>
                            <input {...register("date")} type="date" defaultValue={today} placeholder="Date" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Invoice No</span>
                            </label>
                            <input {...register("invoice")} type="text" placeholder="Type Invoice No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>


                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Remarks</span>
                            </label>
                            <input {...register("purpose")} type="text" placeholder="Type Remarks Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option selected>Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input disabled={!userAc?.addPayment | !currentSupplier?.name} type="submit" value='Add Payment' className='btn w-full lg:w-60 bg-red-600 text-white mt-2 lg:mt-9' />
                    </form>
                </div>
            </div>
            <div className='text-xs mx-2 shadow-2xl p-2 lg:p-5'>
                <div className={`${isExpend === false ? "" : "hidden"}`}>
                    <div className='flex justify-center lg:justify-end items-center'>
                        <form onSubmit={handleFilteredData} className='mt-12 flex flex-col lg:flex-row justify-center items-right'>
                            <div class="form-control w-full lg:w-40 max-w-xs lg:mr-2">
                                <label class="label">
                                    <span class="label-text">Starting Date</span>
                                </label>
                                <input name='startDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                            </div>
                            <div class="form-control w-full lg:w-40 max-w-xs lg:mr-2">
                                <label class="label">
                                    <span class="label-text">End Date</span>
                                </label>
                                <input name='endDate' defaultValue={today} type="date" class="input text-xs input-bordered w-full max-w-xs" required />
                            </div>
                            <div class="form-control w-full lg:w-60 max-w-xs lg:mr-2">
                                <label class="label">
                                    <span class="label-text">Supplier</span>
                                </label>
                                <select style={{ padding: '' }} {...register("category")} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                                    <option selected>All</option>
                                    {
                                        suppliers.map(supplier => <option>{supplier.name}</option>)
                                    }
                                </select>
                            </div>
                            <div class={`form-control w-full lg:w-40 max-w-xs lg:mr-2`}>
                                <label class="label">
                                    <span class="label-text">Short By</span>
                                </label>
                                <select style={{ padding: '' }} {...register("sort")} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                                    <option selected>Newest First</option>
                                    <option>Oldest First</option>
                                </select>
                            </div>

                            <input type='submit' value='Search' className='btn btn-md btn-error text-white mt-4 lg:mt-9 lg:ml-2' />
                        </form>
                    </div>
                    <div className='flex justify-end items-center my-8 lg:my-4'>
                        <div class="form-control w-full lg:w-60">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Payment" class="input text-xs mx-auto input-bordered bg-white input-error w-full max-w-xs" />
                        </div>
                    </div>
                    <div className={`h-8 ${isFilter === false ? "flex justify-between items-center" : "text-right"}`}>
                        <div className={`form-control w-40 max-w-xs lg:mr-2 ${isFilter === true ? "hidden" : ""}`}>
                            <label class="label">
                                <span class="label-text">Short By</span>
                            </label>
                            <select ref={sortRef} style={{ padding: '' }} onChange={handleSort} defaultValue='' class="rounded-lg text-black text-xs select select-bordered w-full max-w-xs" required>
                                <option selected>Newest First</option>
                                <option>Oldest First</option>
                            </select>
                        </div>
                        <div className='text-right'>
                            <ReactHtmlTableToExcel
                                id="test-table-xls-button"
                                className="btn btn-error text-white btn-xs mt-10 lg:mt-0"
                                table="table-to-xls"
                                filename="payments-table"
                                sheet="payments"
                                buttonText="Export to exel" />
                        </div>
                    </div>
                    {
                        payments.length ? <div class="overflow-x-auto mt-8">
                            <table id="table-to-xls" class="table w-1/2 mx-auto">
                                <thead className='text-center'>
                                    <tr>
                                        <th className='bg-red-600 text-white normal-case'>SL</th>
                                        <th className='bg-red-600 text-white normal-case'>Date</th>
                                        <th className='bg-red-600 text-white normal-case'>Supplier Code</th>
                                        <th className='bg-red-600 text-white normal-case'>Supplier Name</th>
                                        <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                                        <th className='bg-red-600 text-white normal-case'>Amount</th>
                                        <th className='bg-red-600 text-white normal-case'>Purpose</th>
                                        <th className='bg-red-600 text-white normal-case'>Status</th>
                                        <th className='bg-red-600 text-white normal-case'>Added By</th>
                                        <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                        <th className='bg-red-600 text-white normal-case'>Edit</th>
                                        <th className='bg-red-600 text-white normal-case'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {
                                        isSearching ? searchResult.map((payment, index) => <tr className='hover'>
                                            <td>{index + 1}</td>
                                            <td>{payment.date}</td>
                                            <td>{payment.code}</td>
                                            <td>{payment.name}</td>
                                            <td>{payment.invoice}</td>
                                            <td>{payment.amount}</td>
                                            <td>{payment.purpose}</td>
                                            <td> <button className={`btn btn-xs normal-case ${payment.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{payment?.status}</button> </td>
                                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{payment?.user ? payment.user : "User"}</button></td>
                                            {userAc.approvalPayment ? <td>{payment?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{payment?.approvedBy}</button> : <button onClick={() => handleApprove(payment)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                                <td>{payment.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{payment.approvedBy}</button> : <button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                            {userAc?.editPayment && <td><button><label for="update-modal" onClick={() => handleUpdatePayment(payment)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                            {userAc?.deletePayment && <td><button onClick={() => handleDeletePayment(payment._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                        </tr>) : payments.map((payment, index) => <tr className='hover'>
                                            <td>{index + 1}</td>
                                            <td>{payment.date}</td>
                                            <td>{payment.code}</td>
                                            <td>{payment.name}</td>
                                            <td>{payment.invoice}</td>
                                            <td>{payment.amount}</td>
                                            <td>{payment.purpose}</td>
                                            <td> <button className={`btn btn-xs normal-case ${payment.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{payment?.status}</button> </td>
                                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{payment?.user ? payment.user : "User"}</button></td>
                                            {userAc.approvalPayment ? <td>{payment?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{payment?.approvedBy}</button> : <button onClick={() => handleApprove(payment)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                                <td>{payment.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{payment.approvedBy}</button> : <button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                            {userAc?.editPayment && <td><button><label for="update-modal" onClick={() => handleUpdatePayment(payment)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                            {userAc?.deletePayment && <td><button onClick={() => handleDeletePayment(payment._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                        </tr>)
                                    }
                                </tbody>
                                <tfoot className='text-center'>
                                    <tr>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'>Total</th>
                                        <th className='bg-red-600 text-white normal-case'>{totalAmount}</th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                        <th className='bg-red-600 text-white normal-case'></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div> : ""
                    }
                </div>
            </div>
            {
                updatePayment && <UpdateModal fetchData={fetchData} setUpdatePayment={setUpdatePayment} updatePayment={updatePayment}></UpdateModal>
            }
        </div>
    );
};

export default PaymentField;