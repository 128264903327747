import React, { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { removeProductFromList } from '../../features/SalesSlice/SalesSlice';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

const AddSalesInvoice = ({ details }) => {
    const { productList } = useSelector((state) => state.salesInvoice);
    const customer = useSelector((state) => state.salesInvoice.bookingCustomer);
    const [dueAmount, setDueAmount] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [isPayment, setIsPayment] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);
    const [isServiceCharge, setIsServiceCharge] = useState(false);
    const [discount, setDiscount] = useState('');
    const [payment, setPayment] = useState('');
    const [serviceCharge, setServiceCharge] = useState('');
    const [sparePartsWithCharge, setSparePartsWithCharge] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const removeFromList = (product) => {
        dispatch(removeProductFromList(product));
    }


    const total = productList.reduce((total, currentValue) => total + parseInt(currentValue?.total), 0);

    const handleDiscount = (event) => {
        const discount = parseInt(event?.target?.value);
        setDiscount(discount);
        // const grand = total - discount;
        // setTotalAmount(grand);
        setIsDiscount(true);
    }
    const handlePayment = (event) => {
        const payment = parseInt(event?.target?.value);
        setPayment(payment);
        // const dueAmount = totalAmount - payment;
        // setDueAmount(dueAmount);
        setIsPayment(true);
        setIsDiscount(true);
    }
    const handleServiceCharge = (event) => {
        let charge = parseInt(event?.target?.value);

        if (customer !== "Walking Customer") {
            if (customer.service.includes("Free")) {
                charge = 0;
            }
        }

        setServiceCharge(charge);
        const spareParts = parseInt(partsAmountRef.current.value);
        setSparePartsWithCharge(spareParts + charge);


        setIsServiceCharge(true)

    }

    const totalRef = useRef('');
    const discountRef = useRef();
    const grandTotalRef = useRef();
    const paymentRef = useRef();
    const dueAmountRef = useRef();
    const pitNoRef = useRef();
    const technicianRef = useRef();
    const partsAmountRef = useRef();
    const serviceChargeAmountRef = useRef();




    const handleSubmitPurchase = () => {
        const totalAmount = parseInt(totalRef?.current?.innerText);
        const discount = parseInt(discountRef?.current?.value);
        const grandTotal = parseInt(grandTotalRef?.current?.value);
        const paymentdata =parseInt(paymentRef.current.value);
        const payment = paymentdata?parseInt(paymentRef.current.value):0;
        const dueAmount = parseInt(dueAmountRef?.current?.value);
        const partsAmount = parseInt(partsAmountRef?.current?.value);
        const chargeAmount = parseInt(serviceChargeAmountRef?.current?.value);
        const technician = technicianRef?.current?.value;
        const pit = pitNoRef?.current?.value;

        const data = { products: productList, ...details, totalAmount, discount, grandTotal, payment, dueAmount, ...customer, partsAmount, chargeAmount, technician }

        fetch('https://servers.thakurgaonexpress.com/mntthai/sales', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast.success('Sales Added Successfull')
                const newdate = new Date();
                const date = newdate.toLocaleDateString();
                const time = newdate.toLocaleTimeString();
                
                if (customer !== "Walking Customer") {
                    fetch(`https://servers.thakurgaonexpress.com/mntthai/getallbooking/${customer._id}`, {
                        method: 'PUT',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ parts: partsAmount, service_charge: chargeAmount, pit: pit, technician: technician, date: date, time: time })
                    })
                        .then(res => res.json())
                        .then(data => {
                            console.log(data);
                            toast('Service Completed');
                        })
                }

            })

            setTimeout(() => {
                    navigate("/saleslist");
                    window.location.reload();
                }, 3000);

       
        // Service Complete Informations


        // const token = `92310756091678413369ac9dc34ff7599524a6ea84a77094beb1`;
        // const number = customer.person_phone;
        // const msg = `প্রিয় ${customer.person_name}, এনবিসি মটরস এর সার্ভিসে আপনার মেমো নং- ${details.salesInvoice}, পার্টস মূল্য- ${partsAmount}Tk,${customer.service} চার্জ ${customer.service.includes('Free') ? 0 : chargeAmount}Tk ,ব্যতিক্রম হলে অভিযোগ 01772545974`;
        // const message = encodeURI(msg)

        // const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${number}&message=${message}`

        // fetch(url)
        //     .then(res => res.json())
        //     .then(data => {
        //         console.log(data);
        //     })

    }
    return (
        <div className='grid grid-cols-12 gap-4 h-screen overflow-auto z-30'>
            <div className='col-span-9'>
                <table class="table w-1/2 mx-auto">
                    <thead className='text-center'>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'>SL#</th>
                            {/* <th className='bg-red-600 text-white normal-case'>Product Code</th> */}
                            <th className='bg-red-600 text-white normal-case'>Product Name</th>
                            <th className='bg-red-600 text-white normal-case'>Quantity</th>
                            <th className='bg-red-600 text-white normal-case'>Rate</th>
                            <th className='bg-red-600 text-white normal-case'>Total</th>
                            <th className='bg-red-600 text-white normal-case'>Action</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {
                            productList?.map((product, index) => <tr className='hover'>
                                <td className='h-8'>{index + 1}</td>
                                {/* <td className='h-8'>{product?.code}</td> */}
                                <td className=''>{product?.name}</td>
                                <td><input type="number" value={product?.quantity} placeholder="Type here" className="input input-bordered text-center h-8 w-20 max-w-lg" /></td>
                                <td><input type="number" value={product?.rate} placeholder="Type here" className="input input-bordered text-center h-8 w-28 max-w-lg" /></td>
                                <td><input type="number" value={product?.total} placeholder="Type here" className="input input-bordered text-center h-8 w-40 max-w-lg" /></td>
                                <td> <button onClick={() => removeFromList(product)} className={`btn btn-sm btn-error cursor-pointer`}><AiOutlineClose /></button> </td>
                            </tr>)
                        }

                    </tbody>
                    <tfoot className='text-center h-2'>
                        <tr>
                            <td></td>
                            {/* <td></td> */}
                            <td></td>
                            <td></td>
                            <td>Total</td>
                            <td ref={totalRef} className='text-lg'>{total}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            {/* <td></td> */}
                            <td></td>
                            <td></td>
                            <td>Discount</td>
                            <td> <input ref={discountRef} onChange={handleDiscount} type="number" placeholder="Type Discount" className="input input-bordered text-center w-48 h-8 mx-auto max-w-lg" /></td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
                <div className='flex gap-2'>
                    {
                        customer !== "Walking Customer" && <div class="form-control w-40 max-w-xs">
                            <label class="label">
                                <span class="label-text">Service Name</span>
                            </label>
                            <input value={customer.service} disabled type="text" placeholder="Enter Pit No" class="input input-bordered w-full max-w-xs" required />
                        </div>
                    }
                    {customer !== "Walking Customer" && <div class="form-control w-40 max-w-xs">
                        <label class="label">
                            <span class="label-text">Pit No</span>
                        </label>
                        <input ref={pitNoRef} type="text" placeholder="Enter Pit No" class="input input-bordered w-full max-w-xs" required />
                    </div>}
                    {customer !== "Walking Customer" && <div class="form-control w-40">
                        <label class="label">
                            <span class="label-text">Select Technician</span>
                        </label>
                        <select ref={technicianRef} class="select select-bordered w-full max-w-xs" required>
                            <option>Hira</option>
                            <option>Pintu</option>
                            <option>Khalil</option>
                        </select>
                    </div>}
                    <div className={`form-control w-40  max-w-xs ml-12`}>
                        <label class="label">
                            <span class="label-text">Spare Parts Amount</span>
                        </label>
                        <input ref={partsAmountRef} value={isDiscount === true ? total - discount : total} type="number" placeholder="Spare Parts Amount" class="input input-bordered w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-60  max-w-xs">
                        <label class="label">
                            <span class="label-text">Service Charge Amount</span>
                        </label>
                        <input ref={serviceChargeAmountRef} onChange={handleServiceCharge} type="number" placeholder="Type Service Charge Amount" class="input input-bordered w-full max-w-xs" required />
                    </div>
                </div>
            </div>
            <div style={{ height: '400px' }} className='bg-red-600 col-span-3 w-60 ml-46 text-center'>
                <table class="table w-1/2 mx-auto">
                    <thead className='text-center'>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'>Grand Total</th>
                        </tr>
                    </thead>
                </table>
                <input ref={grandTotalRef} type="number" value={isDiscount === true ? sparePartsWithCharge - discount + discount : sparePartsWithCharge} placeholder="Type here" className="input input-bordered text-center w-48 mx-auto max-w-lg" />
                <table class="table w-1/2 mx-auto">
                    <thead className='text-center'>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'>Cash Payment</th>
                        </tr>
                    </thead>
                </table>
                <input ref={paymentRef} onChange={handlePayment} type="number" placeholder="Type Payment" className="input input-bordered text-center w-48 mx-auto max-w-lg" />
                <table class="table w-1/2 mx-auto">
                    <thead className='text-center'>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                        </tr>
                    </thead>
                </table>
                <input ref={dueAmountRef} type="number" value={isDiscount === false ? sparePartsWithCharge : isDiscount & isPayment === true ? (sparePartsWithCharge - discount + discount) - payment : (sparePartsWithCharge - discount + discount) - payment} placeholder="Type here" className="input input-bordered text-center w-48 mx-auto max-w-lg" />
                <button onClick={handleSubmitPurchase} className='btn mt-4 text-white'>Add Sales</button>
            </div>
        </div>
    );
};

export default AddSalesInvoice;

//finalAmount === false ? totalAmount : isDue === false ? grandAmount : dueAmount