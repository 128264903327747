import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import Loader from '../../../Shared/Loader';

const LoanDueReport = () => {
    const [loanAccounts, setLoanAccounts] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const componentRef = useRef()

    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Loan Due Report`,

    });

    useEffect(() => {
        const url = `https://servers.thakurgaonexpress.com/mntthai/loanduereport`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setLoanAccounts(data?.loanDues);
                console.log(data?.loanDues);
                setIsLoading(false)
            })
    }, []);


    if (isLoading) {
        return <Loader></Loader>
    }

    const handleSearchResult = (event) => {
        const searchText = event.target.value;
        const match = loanAccounts.filter(loanAccount => loanAccount.name.includes(searchText) || loanAccount.name.toLowerCase().includes(searchText)
            || loanAccount.name.toUpperCase().includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }

    const totalBalance = isSearching ? searchResult.reduce((total, currentValue) => total + parseFloat(currentValue.balance), 0) :
        loanAccounts.reduce((total, currentValue) => total + parseFloat(currentValue.balance), 0);

    return (
        <div className='mx-2'>
             <h1 className='text-center text-lg mt-8'>Loan Due Report</h1>
            <div className='text-right mt-12'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Balance Sheet`}
                    sheet={`balance-sheet`}
                    buttonText="Export to exel" />
                <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
            </div>
            <div className='flex lg:justify-end justify-center items-center mt-4'>
                <div class="form-control w-60">
                    <input onChange={handleSearchResult} type="text" placeholder="Search Loan Accounts" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                </div>
            </div>
            <div ref={componentRef} class="overflow-x-auto">
                <table id='table-to-xls' class="table w-1/2 mx-auto text-xs mb-20">
                    <caption className='text-center text-lg mb-2 mt-16'>Loan Due Report</caption>
                    <thead>
                        <tr className='text-center'>
                            <th className='bg-red-600 text-white normal-case'>SL</th>
                            <th className='bg-red-600 text-white normal-case'>Loan A/C Code </th>
                            <th className='bg-red-600 text-white normal-case'>Loan A/C Name</th>
                            <th className='bg-red-600 text-white normal-case'>Address</th>
                            <th className='bg-red-600 text-white normal-case'>Phone</th>
                            <th className='bg-red-600 text-white normal-case'>Opening Balance</th>
                            <th className='bg-red-600 text-white normal-case'>Total Taken</th>
                            <th className='bg-red-600 text-white normal-case'>Total Return</th>
                            <th className='bg-red-600 text-white normal-case'>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isSearching ? searchResult.map((loanAccount, index) => <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{loanAccount?.loanAccountCode}</td>
                                <td>{loanAccount?.name}</td>
                                <td>{loanAccount?.address}</td>
                                <td>{loanAccount?.phone}</td>
                                <td className='text-blue-800'>{loanAccount?.opening}</td>
                                <td className='text-green-600'>{loanAccount?.totalTaken}</td>
                                <td className='text-yellow-700'>{loanAccount?.totalReturn}</td>
                                <td className='text-red-600 font-bold'>{loanAccount?.balance}</td>
                            </tr>) : loanAccounts.map((loanAccount, index) => <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{loanAccount?.loanAccountCode}</td>
                                <td>{loanAccount?.name}</td>
                                <td>{loanAccount?.address}</td>
                                <td>{loanAccount?.phone}</td>
                                <td className='text-blue-800'>{loanAccount?.opening}</td>
                                <td className='text-green-600'>{loanAccount?.totalTaken}</td>
                                <td className='text-yellow-700'>{loanAccount?.totalReturn}</td>
                                <td className='text-red-600 font-bold'>{loanAccount?.balance}</td>
                            </tr>)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'>Total Loan Dues</th>
                            <th className='bg-red-600 text-white normal-case'>{totalBalance}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default LoanDueReport;